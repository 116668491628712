// Generated by Framer (4f5e97b)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["I340:8990;299:6400;2226:14366"];

const serializationHash = "framer-pnbGX"

const variantClassNames = {"I340:8990;299:6400;2226:14366": "framer-v-1rfqnnp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "I340:8990;299:6400;2226:14366", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-pnbGX", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1rfqnnp", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"I340:8990;299:6400;2226:14366"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-91nfm5"} data-framer-name={"Ellipse 6429"} layoutDependency={layoutDependency} layoutId={"I340:8990;299:6400;2226:14366;181:22145"} style={{backgroundColor: "rgb(101, 219, 106)", borderBottomLeftRadius: "100%", borderBottomRightRadius: "100%", borderTopLeftRadius: "100%", borderTopRightRadius: "100%"}}/><SVG className={"framer-17mat3d"} data-framer-name={"Vector 4685"} fill={"rgba(0,0,0,1)"} intrinsicHeight={8} intrinsicWidth={10} layoutDependency={layoutDependency} layoutId={"I340:8990;299:6400;2226:14366;181:22146"} svg={"<svg width=\"10\" height=\"8\" viewBox=\"-1 -1 10 8\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M0.571289 2.36493L3.25113 5.0726L7.84825 0.427734\" stroke=\"#1B1B1B\" stroke-width=\"1.80634\"/>\n</svg>\n"} transformTemplate={transformTemplate} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-pnbGX [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pnbGX .framer-5b8cqo { display: block; }", ".framer-pnbGX .framer-1rfqnnp { height: 24px; overflow: hidden; position: relative; width: 24px; }", ".framer-pnbGX .framer-91nfm5 { aspect-ratio: 1 / 1; bottom: var(--framer-aspect-ratio-supported, 2px); flex: none; height: 21px; left: 2px; position: absolute; right: 1px; top: 2px; }", ".framer-pnbGX .framer-17mat3d { aspect-ratio: 1.5 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 9px); left: 50%; position: absolute; top: 50%; width: 14px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 24
 * @framerIntrinsicWidth 24
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 */
const FramernHXeN0Chk: React.ComponentType<Props> = withCSS(Component, css, "framer-pnbGX") as typeof Component;
export default FramernHXeN0Chk;

FramernHXeN0Chk.displayName = "[Spr] / Chart Icons";

FramernHXeN0Chk.defaultProps = {height: 24, width: 24};

addFonts(FramernHXeN0Chk, [])